import { useEffect } from "react";
import { BannerSlider } from "components/organisms";
import { useAppSelector } from "hooks/useAppSelector";
import Loader from "components/molecules/loader";
import { getPromotions } from "actions/promotions/services";
import SectionCardsPromotions from "components/organisms/section-cards-promotions";
import { useNavigate } from "react-router-dom";

const Promotions = () => {
  const navigate = useNavigate();
  const { language, coin, loading, data, banners, banner_tira, sections } =
    useAppSelector((state) => ({
      language: state.config.language,
      loading: state.language.loading,
      coin: state.config.coin,
      data: state.language.dataPromotion,
      banners: state.language.dataPromotion?.banners || [],
      banner_tira: state.language.dataPromotion?.banner_tira || [],
      sections: state.language.dataPromotion?.secciones || [],
    }));

  useEffect(() => {
    if (language && coin) {
      getPromotions({ language, coin, navigate });
      window.scrollTo(0, 0);
    }
  }, [language, coin]);

  if (loading || !data) return <Loader />;

  return (
    <>
      {banners.length > 0 && <BannerSlider data={banners} />}

      {sections.length > 0 && (
        <SectionCardsPromotions data={sections} banner={banner_tira} />
      )}
    </>
  );
};

export default Promotions;
