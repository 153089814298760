
import Axios from "actions/api";
import { IGetCountdown } from "./props";
import { endpoints } from "actions/endpoints/services";
import * as Sentry from '@sentry/react';

export const getCountdown = async ({
  language,
  setCountdown,
  setPromoBannerTira,
  navigate,
}: IGetCountdown) => {
  try {
    const response = await Axios.request({
      method: "get",
      url: endpoints.contador,
      headers: {
        "Accept-Language": language,
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    });

    const data = response.data;

    const promocion_banner_tira = data.promocion_banner_tira ? data.promocion_banner_tira[0] : null
    setPromoBannerTira(promocion_banner_tira)
    setCountdown(data.contador)

    return data;

  } catch (error) {
    // alert('No se pudo cargar el contador')
    console.log("Server error: ", error);
    Sentry.captureException(error);
    navigate('/es/error');
  }
}
