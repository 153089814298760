import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { BannerChronometer, Footer, Navbar } from "../../organisms";
import "./layout.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SUPPORT_LANG } from "utils/valuesForms";
import { useAppSelector } from "hooks/useAppSelector";
import { getCountdown } from "actions/countdown/services";
import { ICountdownProps } from "../banners/banner-chronometer";
import BannerTiraPromocion, { IPromocionBannerTira } from "../banners/banner-tira-promocion";

const Layout = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const language = useAppSelector((state) => state.config.language);

  const [showBanner, setShowBanner] = useState(false);
  const [dataCountdown, setCountdown] = useState<ICountdownProps|null>(null);
  const [dataPromoBannerTira, setPromoBannerTira] = useState<IPromocionBannerTira|null>(null);

  const handleBannerClose = () => {
    setShowBanner(false);
    localStorage.setItem('bannerDate', new Date().toDateString());
  };

  const shouldShowFooter = !(
    location.pathname === `/${i18n.language}/${t("routes.hotels")}`
  );

  const shouldShowBanner = showBanner && dataPromoBannerTira;
  const shouldShowContador = !shouldShowBanner && showBanner && dataCountdown && dataCountdown.contador;

  useEffect(() => {
    if (!dataCountdown && !dataPromoBannerTira) return;

    const storedDate = localStorage.getItem('bannerDate');
    const currentDate = new Date().toDateString();
    console.log('storedDate', storedDate)
    console.log('currentDate', currentDate)
    // Mostrar el banner si la fecha es diferente o no existe en el localStorage
    if (storedDate && storedDate == currentDate) {
      setShowBanner(false);
    } else {
      setShowBanner(true);
    }
  }, [dataCountdown, dataPromoBannerTira]);

  useEffect(() => {
    if (language) {
      getCountdown({ language, setCountdown, setPromoBannerTira, navigate });
    }
  }, [language]);

  useEffect(() => {
    const pathParts = location.pathname.split("/");
    let lang: string = pathParts[1];

    if (!lang) {
      lang = localStorage.getItem("i18nextLng") || navigator.language || "es";
      navigate(`/${lang}/home`, { replace: true });
    } else if (!SUPPORT_LANG.includes(lang)) {
      navigate("*", { replace: false });
    }

    if (lang !== i18n.language) {
      lang = SUPPORT_LANG.includes(lang) ? lang : "es";
      i18n.changeLanguage(lang);
      localStorage.setItem("i18nextLng", lang);
    }
  }, [location.pathname]);

  return (
    <>
      { shouldShowBanner && (
          <BannerTiraPromocion
            promoBannerTira={dataPromoBannerTira}
            onClose={handleBannerClose}
          />
      )}

      { shouldShowContador && (
          <BannerChronometer
            countdown={dataCountdown}
            onClose={handleBannerClose}
          />
      )}

      <div className="layout__general">
        <Navbar />
        <main className="layaout__general--main">
          <Outlet />
        </main>
        {shouldShowFooter && <Footer />}
      </div>
    </>
  );
};

export default Layout;
