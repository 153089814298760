import React, { useState } from 'react'
import './styles.scss'
import Paragraph from '../paragraph'
import Icon from '../icon'
import { useDetectClickOutside } from 'react-detect-click-outside'
import Text from '../text'
import { IMultiSelectDropdown } from './props'

const MultiSelectDropdown = ({
  options,
  className,
  onSelect,
  title
}: IMultiSelectDropdown) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);

  const closeDropdown = () => {
    setIsOpen(false)
  }

  const handleSelect = (option: any) => {
    const isSelected = selectedOptions.includes(option);
    const updatedOptions = isSelected
      ? selectedOptions.filter((o) => o !== option)
      : [...selectedOptions, option];

    setSelectedOptions(updatedOptions);
    onSelect(updatedOptions);
  };

  const clearSelections = () => {
    setSelectedOptions([]);
    onSelect([]);
  };

  const ref = useDetectClickOutside({ onTriggered: closeDropdown })

  return (
    <div className={`multi-select-dropdown ${className}`} ref={ref}>
      <div className='dropdown-toggle' onClick={() => setIsOpen(!isOpen)}>
        <div className='d-flex jc-space-between ai-center dropdown-toogle-arrow'>
          <div style={{ width: '100%' }}>
            <Paragraph text={title} className={'title'} />
            <div className='d-flex fd-row'>
              <Paragraph text={selectedOptions.map(option => option.nombre).join(', ')} className={'option'} />
              {selectedOptions.length > 0 && (
                <Icon className="clear-button" name="close" list="items" width={20} height={20} onClick={clearSelections}/>
              )}
            </div>
          </div>
          <Icon
            name={isOpen ? 'up' : 'down'}
            list='arrows'
            width={24}
            height={24}
          />
        </div>
      </div>
      {isOpen && (
        <ul className='dropdown-list'>
          {options?.map((option: any) => (
            <li key={option.id} onClick={() => handleSelect(option)}>
              <input
                type="checkbox"
                checked={selectedOptions.includes(option)}
                onChange={() => handleSelect(option)}
              />
              <Text text={option.nombre} className={selectedOptions.includes(option) ? 'is-selected' : ''} />
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}


MultiSelectDropdown.defaultProps = {
  title: 'Ordenar por'
}

export default MultiSelectDropdown
